import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../components/SEO';

import Layout from '../components/layout';
import logo from '../images/Logo_white@2x.png';

export default props => {
  return (
    <>
      <SEO
        title="Laskutus- ja peruutusehdot"
        description="Laskutus- ja peruutusehdot"
      />
      <Layout
        additionalClassForWrapper="contact-bg"
        headerBgStyles={{
          display: 'flex',
          flexDirection: 'column',
        }}
        headerNavLinkStyle={{ color: 'white' }}
        mainTitle={'Laskutus- ja peruutusehdot'}
        mainTitleClasses={'mt-5 mb-5'}
        headerTitleStyle={{ color: 'white' }}
        logo={logo}
        {...props}
      >
        <div className="container">
          <div className="row d-flex justify-content-center pt-0 pb-4">
            <div className="col-lg-12 pt-5 pr-md-6">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.termsAndConditionsSnippet.html,
                }}
              ></div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query termsAndConditionsQuery2 {
    termsAndConditionsSnippet: markdownRemark(
      frontmatter: {
        type: { eq: "snippet" }
        id: { eq: "terms-and-conditions" }
      }
    ) {
      html
    }
  }
`;
